import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'

type myProps = {
    number: number,
    heading: string,
    content: string
}
const BuySteps:React.FC<myProps> = ({number, heading, content}) => {
  return (
    <Box m={2} width={'100%'}>
        <Typography fontSize={50} fontWeight='bold' color={'#009C19'}>0{number}.</Typography>
        <Typography fontSize={20} color={'#fff'}>{heading}</Typography>
        <Typography fontSize={12} color={'#fff'}>{content}</Typography>
    </Box>
  )
}

export default BuySteps