//@ts-nocheck
import React, { useEffect } from "react";
import styles from "../pages/Home.module.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";

declare global {
  interface Window {
    web3: any;
  }
}

interface NavBarProps {
  address?: string;
  setAddress: (x: string) => void;
}

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      color="inherit"
      sx={{ backgroundColor: "rgba(0, 0, 0)", padding: 2 }}
      elevation={0}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={require("../media/images/logo.png")}
            className={styles.logo}
            alt="logo"
            width="10%"
          />

          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "flex",
                md: "none",
                justifyContent: "flex-end",
              },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "#019C1A" }} />
            </IconButton>
            <Menu
              elevation={2}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem></MenuItem>

              <MenuItem>
                <Button
                  sx={{
                    borderRadius: 0,
                    padding: 1,
                    color: "#000",
                    backgroundImage: "linear-gradient(#00FF94, #019C1A)",
                  }}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  component={Link}
                  to=""
                  target="_blank"
                >
                  Buy Now
                </Button>
              </MenuItem>
            </Menu>
          </Box>
          {/* <img src={logo} alt="logo" width={'10%'} /> */}

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          ></Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "space-evenly",
            }}
          >
            <Button
              sx={{
                borderRadius: 0,
                padding: "2% 4%",
                color: "#000",
                backgroundImage: "linear-gradient(#00FF94, #019C1A)",
                fontWeight: 800,
              }}
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              component={Link}
              to="https://kyberswap.com/swap/ethereum/eth-to-bullfi"
              target="_blank"
            >
              Buy $BULLFI
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
