import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import styles from "./Home.module.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ContentBox from "../components/ContentBox";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import BuySteps from "../components/BuySteps";
import "../fonts/CHEESEBURGA.ttf";
import PhaseBox from "../components/PhaseBox";
import PlaceIcon from "@mui/icons-material/Place";
import LanguageIcon from "@mui/icons-material/Language";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { list1 } from "../constants/lists";
import { list2 } from "../constants/lists";
import { list3 } from "../constants/lists";
import { Link } from "react-router-dom";
import { SwapWidget } from "@uniswap/widgets";
import "@uniswap/widgets/fonts.css";
import { BigNumber, ethers } from "ethers";
import claimABI from "../utils/claimABI.json";
import Web3 from "web3";
import { Web3Provider, JsonRpcProvider } from "@ethersproject/providers";
import { Widget } from "@kyberswap/widgets";
import "./kyberswap.css";

const darkTheme = {
  text: "#FFFFFF",
  subText: "#FFFFFF",
  primary: "#189470",
  dialog: "#111A12",
  secondary: "#1A9C1A",
  interactive: "#189470",
  stroke: "#189470",
  accent: "#FFFFFF",
  success: "#189470",
  warning: "#FF9901",
  error: "#FF537B",
  fontFamily: "Work Sans",
  borderRadius: "18px",
  buttonRadius: "9px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.04)",
};

const goerliKey =
  "https://eth-goerli.g.alchemy.com/v2/ArJv7fD_shwRMnorANvL3xzN2pWUyWdb";
const arbitrumKey =
  "https://arb-mainnet.g.alchemy.com/v2/vuY42JbKH51zNL_sc6J9VCtv7Y_C6tHL";
const jsonRpcUrlMap = {
  5: goerliKey,
  42161: arbitrumKey,
};

const TOKEN_LIST = "https://gateway.ipfs.io/ipns/tokens.uniswap.org";

declare global {
  interface Window {
    ethereum: any;
    web3: any;
  }
}

const HomePage = () => {
  const twitter = () => {
    window.location.href = "https://twitter.com/staybullfi";
  };
  const discord = () => {
    window.location.href = "https://discord.gg/staybullfi";
  };
  const telegram = () => {
    window.location.href = "https://t.me/staybullfi";
  };

  let ethersProvider;

  ethersProvider = new ethers.providers.JsonRpcProvider(
    "https://mainnet.infura.io/v3/19de54b594234ffb978a4e81f18a9827"
  );

  return (
    <Box>
      <NavBar />
      <div className={styles.background}>
        <div className={styles.innerDiv}>
          <div style={{ marginLeft: "5%", marginTop: "30px" }}>
            <Typography
              color={"#FFF"}
              fontFamily="Cheeseburga"
              letterSpacing={2}
              fontSize={100}
              textTransform={"uppercase"}
              className={styles.title}
            >
              Fuck the Bear Cucks! Time for BULLS !!!!
            </Typography>
            <Typography color={"#8BFF7A"}>
              Stay $BULL'ish !!! Buyers will get 5 btc in 2027
            </Typography>
          </div>

          <div>
            <img
              className={styles.img}
              src={require("../media/images/2.png")}
              alt="bg"
              width={"100%"}
            />
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            className={styles.button}
            sx={{
              borderRadius: 0,
              padding: "5%",
              marginLeft: 7,
              color: "#000",
              backgroundColor: "#8BFF7A",
              marginBottom: 2,
              fontWeight: 800,
            }}
            component={Link}
            to="https://kyberswap.com/swap/ethereum/eth-to-bullfi"
            target="_blank"
            endIcon={<ArrowForwardIcon />}
            color="success"
            variant="contained"
          >
            Buy Now
          </Button>
          <Button
            className={styles.button}
            component={Link}
            to="https://dexscreener.com/ethereum/0x632b22be01bed6cf1f5ae06cfe7c216a0dcf96de"
            target="_blank"
            sx={{
              borderRadius: 0,
              padding: "5%",
              color: "#000",
              marginLeft: 7,
              marginBottom: 2,
              backgroundColor: "#8BFF7A",
              fontWeight: 800,
            }}
            endIcon={<ArrowForwardIcon />}
            color="success"
            variant="contained"
          >
            DexTools
          </Button>
        </div>
      </div>

      <div className={styles.repeatBg}>
        {/* <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4}>
            <ContentBox title="Swap" />
          </Grid>

          <Grid item xs={4}>
            <ContentBox title="Call Option" />
          </Grid>

          <Grid item xs={4}>
            <ContentBox title="Staking / Farming" />
          </Grid>

          <Grid item xs={4}>
            <ContentBox title="$oBULL - Option $BULL" />
          </Grid>

          <Grid item xs={4}>
            <ContentBox title="$soBULL - Staked Option $BULL" />
          </Grid>

          <Grid item xs={4}>
            <ContentBox title="$BULL - Liquid $BULL" />
          </Grid>
        </Grid> */}

        <div className={styles.about}>
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={require("../media/images/meme1 1.png")}
              alt="memeImg"
              width={"30%"}
              className={styles.meme}
            />
          </div>

          <div className={styles.aboutUs}>
            <Typography
              variant="h2"
              fontFamily="Bahiana"
              color={"#fff"}
              sx={{ textAlign: { xs: "center", lg: "start" } }}
            >
              ABOUT
            </Typography>

            <Typography
              variant="body1"
              fontFamily="Poppins"
              fontSize={24}
              color={"#fff"}
            >
              BULLFI is tired of watching everyone play hot sweet potato with
              the endless derivative Inu coins. The Inu’s have had their day.
              It’s time for the bulls to take their reign as king of the memes.
            </Typography>
            <Typography
              variant="body1"
              fontFamily="Poppins"
              fontSize={24}
              color={"#fff"}
            >
              BULLFI is here to make memecoins great again. Launched stealth
              with NO PRESALE, ZERO TAXES, LP burnt and contract renounced,
              $BULLFI is a coin for the FRENS, forever.
            </Typography>
          </div>
        </div>
      </div>

      <div className={styles.buy}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Typography
              sx={{ textAlign: "center" }}
              color={"#fff"}
              fontSize={70}
              fontFamily="Cheeseburga"
              letterSpacing={2}
              className={styles.title}
            >
              HOW TO BUY
            </Typography>
          </div>
          <div
            style={{
              position: "absolute",
              right: 0,
              marginRight: 20,
            }}
          >
            <IconButton
              className={styles.iconButton}
              sx={{
                backgroundImage: "linear-gradient(#fff, #fff)",
                borderRadius: 0,
                padding: 2,
                margin: 0.5,
              }}
            >
              <CallReceivedIcon sx={{ color: "#000" }} />
            </IconButton>
            <IconButton
              className={styles.iconButton}
              sx={{
                backgroundImage: "linear-gradient(#00FF94, #019C1A)",
                borderRadius: 0,
                padding: 2,
                margin: 0.5,
              }}
            >
              <CallMadeIcon sx={{ color: "#000" }} />
            </IconButton>
          </div>
        </div>
        <div>
          <Grid
            className={styles.steps}
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={3}>
              <BuySteps
                number={1}
                heading={"Create a Wallet"}
                content={
                  "Download metamask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to metamask.io."
                }
              />
            </Grid>

            <Grid item xs={3}>
              <BuySteps
                number={2}
                heading={"Get some $ETH"}
                content={
                  "Have ETH in your wallet to swap to $BULLFI. If you don’t have any ETH, you can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it to your wallet."
                }
              />
            </Grid>

            <Grid item xs={3}>
              <BuySteps
                number={3}
                heading={"Got to DEX"}
                content={
                  "Connect to Kyberswap. Go to https://kyberswap.com/swap/ethereum in google chrome or on the browser inside your Metamask app. Connect your wallet. Paste the $BULLFI token address into Uniswap, select BULL, and confirm. When Metamask prompts you for a wallet signature, sign."
                }
              />
            </Grid>

            <Grid item xs={3}>
              <BuySteps
                number={4}
                heading={"Swap $ETH for $BULLFI"}
                content={
                  "switch $USDC for $BULLFI. We have ZERO taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility."
                }
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className={styles.button}
            sx={{
              borderRadius: 0,
              fontWeight: 800,
              padding: "1.5%",
              marginTop: 20,
              marginLeft: "auto", // Adjusted for responsiveness
              marginRight: "auto", // Adjusted for responsiveness
              color: "#000",
              backgroundColor: "#8BFF7A",
              marginBottom: 20,
              "@media (max-width: 768px)": {
                // Adjustments for smaller screens
                width: "80%", // Change width for smaller screens
                marginLeft: "auto", // Center align for smaller screens
                marginRight: "auto", // Center align for smaller screens
              },
            }}
            component={Link}
            to="https://kyberswap.com/swap/ethereum/eth-to-bullfi"
            target="_blank"
            endIcon={<ArrowForwardIcon />}
            color="success"
            variant="contained"
          >
            Buy Now on KyberSwap
          </Button>
        </div>

        {/* <div className={styles.swapWrapper}>
          <div className="kyber-custom">
            <Widget
              theme={darkTheme}
              tokenList={[]}
              provider={ethersProvider}
              defaultTokenOut={"0x79fF96e85d3a905258063efA8C684E160247Fb3f"}
              width={100}
              // @ts-ignore
              feeSetting={{
                chargeFeeBy: "currency_in",
                feeAmount: 500,
              }}
            />
          </div>
        </div> */}
      </div>

      <div className={styles.token}>
        <Typography
          fontSize={60}
          align="center"
          color={"#000"}
          fontFamily="Cheeseburga"
          letterSpacing={2}
        >
          TOKENNOMICS
        </Typography>
        <Typography
          fontSize={100}
          align="center"
          color={"#000"}
          fontFamily={"Bahiana, cursive"}
        >
          100,000,000
        </Typography>
        <Grid container direction={{ xs: "column", lg: "row" }} pb={10} gap={2}>
          <Grid
            item
            xs={12}
            lg={4}
            className={styles.tokennomics1}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              {/* <img
                src={require("../media/images/frame3.png")}
                alt="outterMeme"
                style={{
                  margin: "auto",
                }}
              /> */}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            className={styles.tokennomics2}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              align="center"
              color={"#000"}
              fontSize={50}
              fontFamily={"Bahiana, cursive"}
            >
              TOKEN SUPPLY
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <img
                src={require("../media/images/bull-distribution.png")}
                alt="innerCircle"
                width="70%"
              /> */}
            </div>
            <div>
              {/* <img
                src={require("../media/images/airdrop-distribution.png")}
                alt="outterMeme"
              /> */}
            </div>
          </Grid>
          {/* <Grid
            item
            xs={12}
            lg={3}
            className={styles.tokennomics3}
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "center",
            }}
          >
            <div>
              <img
                src={require("../media/images/Chart.png")}
                alt="outterMeme"
                style={{ margin: "auto" }}
              />
            </div>
          </Grid> */}
        </Grid>
      </div>

      {/* <div className={styles.roadMap}>
        <Typography
          sx={{ textAlign: "center" }}
          color={"#fff"}
          fontSize={70}
          fontFamily="Cheeseburga"
          letterSpacing={2}
          className={styles.title}
        >
          ROAD MAP
        </Typography>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4}>
            <PhaseBox
              icon={<PlaceIcon />}
              phase={1}
              list={list1}
              bgColor={false}
            />
          </Grid>

          <Grid item xs={4}>
            <PhaseBox
              icon={<LanguageIcon />}
              phase={2}
              list={list2}
              bgColor={true}
            />
          </Grid>

          <Grid item xs={4}>
            <PhaseBox
              icon={<SignalCellularAltIcon />}
              phase={3}
              list={list3}
              bgColor={false}
            />
          </Grid>
        </Grid>
      </div> */}

      <div className={styles.contact}>
        {/* <Typography
          color={"#fff"}
          fontSize={60}
          fontFamily="Bahiana"
          letterSpacing={2}
        >
          CONTACT
        </Typography> */}

        {/* <Button
          sx={{
            color: "#00FF94",
            letterSpacing: 2,
            fontFamily: "Montserrat",
            fontSize: 20,
          }}
        >
          Join Our Community
        </Button>
        <div style={{ marginTop: 5 }}>
          <button
            style={{
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            onClick={discord}
          >
            <img
              src={require("../media/icons/discord.png")}
              style={{ margin: 5 }}
              width="50%"
              alt="discordLogo"
            />
          </button>
          <button
            style={{
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            onClick={telegram}
          >
            <img
              src={require("../media/icons/telegram.png")}
              style={{ margin: 5 }}
              width="50%"
              alt="telegramLogo"
            />
          </button>
          <button
            style={{
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            onClick={twitter}
          >
            <img
              src={require("../media/icons/Twitter.png")}
              style={{ margin: 5 }}
              width="50%"
              alt="twitterLogo"
            />
          </button>
        </div> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            paddingBottom: "30px",
          }}
        >
          <img
            src={require("../media/images/meme1 1.png")}
            alt="meme1"
            width="20%"
            className={styles.memeClass}
          />
          <img
            src={require("../media/images/logo.png")}
            alt="meme2"
            width="20%"
          />
          <img
            src={require("../media/images/dsasdasd 1.png")}
            alt="meme3"
            width="20%"
            className={styles.memeClass}
          />
        </div>
      </div>
    </Box>
  );
};

export default HomePage;
